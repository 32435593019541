<template>
  <div class="service-layout">
    <b-modal id="photo-explorer" centered size="lg" hide-header hide-footer>
      <img width="100%" :src="selectedPhoto">
    </b-modal>
    <b-container>
      <h1 class="text-center mb-4"><span class="blue-text">Видео</span>галерея</h1>
      <article class="about-us text-center mb-4">Здесь мы выкладывем наши работы чтобы Вы смоги убедиться в нашем профессионализме. Если Вы ищите электрика в Красноярске - посмотрите наши работы и сделайте правильный выбор!</article>
      <b-row>
        <div class="col-lg-1 col-12 d-none d-lg-flex text-center">
          <i class="material-icons-outlined my-auto video_nav" @click="prevVideo()">navigate_before</i>
        </div>
        <div class="col-lg-10 col-12">
          <iframe class="video_you figure_video"
                  :src="videos[activeVideo]"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
          </iframe>
        </div>
        <div class="col-lg-1 col-12 text-center d-flex justify-content-between">
          <i class="material-icons-outlined my-auto video_nav d-block d-lg-none" @click="prevVideo()">navigate_before</i>
          <i class="material-icons-outlined my-auto video_nav" @click="nextVideo()">navigate_next</i>
        </div>
      </b-row>
      <h1 class="text-center mb-4 mt-3"><span class="blue-text">Фото</span>галерея</h1>
      <b-row>
        <div class="col-12 col-md-6 col-lg-3 mt-3" v-for="(photo, index) in photos" :key="index">
          <div class="figure_photo">
            <img @click="showPhoto(photo)" :src="photo"/>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import photo1 from "../assets/photo1.jpg"
import photo2 from "../assets/photo2.jpg"
import photo3 from "../assets/photo3.jpg"
import photo4 from "../assets/photo4.jpg"
import photo5 from "../assets/photo5.jpg"
import photo6 from "../assets/photo6.jpg"
import photo7 from "../assets/photo7.jpg"

export default {
  name: "Works",
  data() {
    return {
      activeTile: 1,
      videos: ["https://www.youtube.com/embed/3PrJuXmDSiY", "https://www.youtube.com/embed/BnDk_Xv5lp8"],
      photos: [
        photo1, photo2, photo3, photo4, photo5, photo6, photo7
      ],
      activeVideo: 0,
      selectedPhoto: false
    }
  },
  methods: {
    prevVideo() {
      this.activeVideo = Math.abs((this.activeVideo - 1)) % this.videos.length
      console.log(this.activeVideo)
    },
    nextVideo() {
      this.activeVideo = (this.activeVideo + 1) % this.videos.length
      console.log(this.activeVideo)
    },
    showPhoto(photo) {
      this.selectedPhoto = photo
      this.$bvModal.show("photo-explorer")
    }
  }
}
</script>

<style scoped lang="scss">
@import "../style";

.service-layout {
  padding-top: 110px;
}

.video_you {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.arrow_l {
  transform: scale(-1, 1);
  position: absolute;
  height: 50%;
  width: 50%;
  left: 25%;
  top: 25%;

}

.arrow_r {
  position: absolute;
  height: 50%;
  width: 50%;
  left: 25%;
  top: 25%;
}

.figure_video {
  height: 550px;
  border-radius: 50px;
  padding: 20px 20px;

  margin-left: auto;
  margin-right: auto;
  border: none;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  color: white;
  animation: glow infinite ease-in-out 2s;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  animation-delay: 1s;
}

.figure_photo {
  border-radius: 30px;
  padding: 20px 20px;
  font-size: 32px;
  //margin-left: 10px;
  //margin-right: 10px;
  //margin-bottom: 10px;
  //margin-top: 10px;
  border: none;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  color: white;
  animation: glow infinite ease-in-out 2s;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  animation-delay: 1s;
  //width: 300px;
  //height: 300px;
  display: contents;
}

.figure_photo img {
  border-radius: 30px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video_nav {
  font-size: 64px;
  color: var(--blue-main);
  cursor: pointer;
}

.about-us{
  font-size: 20px;
}
</style>